import { RateType } from 'lib/enums';
import { AdRate, ERate } from 'lib/types';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { isNoticeRate } from 'lib/types/rates';
import { DayRates } from './DayRates';
import { OffsetWithFlatFee } from './OffsetWithFlatFee';
import { BoldFees } from './BoldFees';
import { DistributeFees } from './DistributeFees';
import { FCCOffsetWithFlatFee } from './FCCOffsetWithFlatFee';

const areOffsetsEnabled = (rate: AdRate) => {
  if (rate.rateType === RateType.flat.value) return false;
  if (rate.rateType === RateType.nebraska.value) return false;
  if (rate.rateType === RateType.per_run.value) return false;
  return true;
};

type AdvancedSettingsCardProps = {
  onUpdateRateData: (newRate: AdRate | ERate) => void;
  initialRateData: AdRate | ERate;
  updatedRateData: AdRate | ERate;
  // Temporary prop until we can generalize the new offset fee behavior
  isFCCPaper: boolean;
};

export default function AdvancedSettingsCard({
  onUpdateRateData,
  initialRateData,
  updatedRateData,
  isFCCPaper
}: AdvancedSettingsCardProps) {
  const offsetsEnabled = areOffsetsEnabled(updatedRateData);
  const isEditingNoticeRate =
    isNoticeRate(initialRateData) && isNoticeRate(updatedRateData);

  return (
    <CardGridLayout
      header={{
        title: 'Advanced Settings',
        description:
          'Take fine-grained control over how this rate is charged and appears to customers.'
      }}
    >
      {isEditingNoticeRate && (
        <DayRates
          values={updatedRateData.dayRates ?? null}
          initialValue={initialRateData.dayRates ?? null}
          onChange={dayRates =>
            onUpdateRateData({ ...updatedRateData, dayRates })
          }
        />
      )}

      {offsetsEnabled && !isFCCPaper && (
        <OffsetWithFlatFee
          value={updatedRateData}
          initialValue={initialRateData}
          onChange={changes =>
            onUpdateRateData({ ...updatedRateData, ...changes })
          }
        />
      )}

      {isFCCPaper && offsetsEnabled && (
        <FCCOffsetWithFlatFee
          value={updatedRateData}
          initialValue={initialRateData}
          onChange={changes =>
            onUpdateRateData({ ...updatedRateData, ...changes })
          }
        />
      )}

      {isEditingNoticeRate && (
        <GridInput fullWidth>
          <LabeledSwitch
            label="Hide prices during placement?"
            description="If enabled, customers won't see expected prices while they create their notice."
            value={Boolean(updatedRateData.hidePreview)}
            onChange={val => {
              onUpdateRateData({
                ...updatedRateData,
                hidePreview: val
              });
            }}
          />
        </GridInput>
      )}

      <BoldFees
        value={updatedRateData}
        initialValue={initialRateData}
        onChange={changes =>
          onUpdateRateData({ ...updatedRateData, ...changes })
        }
      />

      {isEditingNoticeRate && (
        <DistributeFees
          value={updatedRateData.distributeEnoticeFee}
          initialValue={initialRateData.distributeEnoticeFee}
          onChange={distributeEnoticeFee =>
            onUpdateRateData({ ...updatedRateData, distributeEnoticeFee })
          }
        />
      )}
    </CardGridLayout>
  );
}
